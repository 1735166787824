/* eslint-disable import/no-default-export */
import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { Layout } from '../components/Layout';
import { ITagPageContext } from '../interfaces/pageContext';
import { TagPosts } from '../components/TagPosts';
import { INodePostSummary } from '../interfaces/blog';
import { HeroTagImage } from '../img/svg/HeroTagImage';

export interface ITagPosts {
  allWordpressPost: {
    edges: INodePostSummary[];
  };
}

const Tag: React.FC<ITagPageContext> = ({ pageContext }) => {
  const { name: tag } = pageContext;

  const data: ITagPosts = useStaticQuery(
    graphql`
        query TagPage {
            allWordpressPost {
                edges {
                    node {
                        id
                        title
                        featured_media {

                            alt_text
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 352) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                        tags {
                            id
                            name
                            slug
                        }
                        author {
                            name
                            slug
                            avatar_urls {
                                wordpress_48
                            }
                        }
                        date(formatString: "MMMM DD, YYYY", locale: "pl")
                        slug
                    }
                }
            }

    }`,
  );

  const postsWithTags = data.allWordpressPost.edges
    .filter((edge) => {
      if (edge.node.tags) {
        return edge.node.tags.some(tagData => tagData.slug === tag);
      }
      return false;
    });

  return (
    <Layout>
      <Helmet title={`${tag} - lista postów | swiatubezpieczen.com`}>
        <meta name="robots" content="noindex, follow" />
      </Helmet>
      <section id="hero" className="section section--tag-hero-image">
        <div className="wrapper">
          <HeroTagImage />
        </div>
      </section>
      <TagPosts posts={postsWithTags} title={tag} />
    </Layout>
  );
};

export default Tag;
