import React from 'react';
import { INodePostSummary } from '../interfaces/blog';
import { PostsListGrid } from './PostsListGrid';

type IProps = {posts: INodePostSummary[]} & { title: string };

export function TagPosts(props: IProps) {
  const { posts, title } = props;

  return (
    <section id="latest" className="section">
      <div className="wrapper">
        <span className="heading-short">Tag</span>
        <h1 className="heading">{title}</h1>
        <PostsListGrid posts={posts} />
      </div>
    </section>
  );
}
