import React from 'react';

export const HeroTagImage = () => (
  <svg
    id="prefix__Warstwa_1"
    x={0}
    y={0}
    viewBox="0 0 1149.2 666"
    xmlSpace="preserve"
  >
    <style>
      {
        '.prefix__st0-tag{fill:none}.prefix__st1-tag{fill:#ff5842}.prefix__st2-tag{enable-background:new}.prefix__st3-tag{fill:#02f}.prefix__st5-tag{fill:#ffaba0}'
      }
    </style>
    <pattern
      y={666}
      width={302.6}
      height={216.6}
      patternUnits="userSpaceOnUse"
      id="prefix__Nowy_wzorek_2"
      viewBox="0 -216.6 302.6 216.6"
      overflow="visible"
    >
      <path className="prefix__st0-tag" d="M0-216.6h302.6V0H0z" />
      <path className="prefix__st0-tag" d="M0-216.6h302.6V0H0z" />
      <path
        className="prefix__st1-tag"
        d="M298.4-32.4H2.6c-1.4 0-2.6.4-2.6.8s1.2.8 2.6.8h295.8c1.4 0 2.6-.3 2.6-.8s-1.2-.8-2.6-.8zM299.8-63.1H2.2c-.7 0-1.2.3-1.2.8s.6.8 1.2.8h297.5c.7 0 1.2-.3 1.2-.8s-.5-.8-1.1-.8zM300.2-93.8H1.8c-.4 0-.8.3-.8.8s.3.8.8.8h298.3c.4 0 .8-.3.8-.8s-.3-.8-.7-.8zM299.9-124.5H2c-.4 0-.8.3-.8.8s.4.7.8.7h297.9c.4 0 .8-.3.8-.8s-.4-.7-.8-.7zM299.9-155.2H2c-.4 0-.8.3-.8.8s.3.8.8.8h297.9c.4 0 .8-.3.8-.8s-.4-.8-.8-.8zM301.9-185.9H4c-.4 0-.8.3-.8.8s.3.8.8.8h297.9c.4 0 .8-.3.8-.8s-.4-.8-.8-.8zM299.9-216.6H2c-.4 0-.8.3-.8.8s.3.8.8.8h297.9c.4 0 .8-.3.8-.8s-.4-.8-.8-.8z"
      />
      <path
        className="prefix__st1-tag"
        d="M151-216.3c-.4 0-.8.4-.8.8V-1c0 .4.3.8.8.8s.8-.3.8-.8v-214.5c0-.5-.4-.8-.8-.8zM129.6-216.3c-.4 0-.8.4-.8.8V-1c0 .4.3.8.8.8s.8-.3.8-.8v-214.5c-.1-.5-.4-.8-.8-.8zM108.1-216.3c-.4 0-.8.4-.8.8V-1c0 .4.3.8.8.8s.8-.3.8-.8v-214.5c0-.5-.4-.8-.8-.8zM86.7-216.3c-.4 0-.8.4-.8.8V-1c0 .4.3.8.8.8s.8-.3.8-.8v-214.5c-.1-.5-.4-.8-.8-.8zM65.2-216.3c-.4 0-.8.4-.8.8V-1c0 .4.3.8.8.8s.8-.4.8-.8v-214.5c0-.5-.3-.8-.8-.8zM43.8-216.3c-.4 0-.8.4-.8.8V-1c0 .4.3.8.8.8s.8-.3.8-.8v-214.5c0-.5-.4-.8-.8-.8zM22.4-216.3c-.4 0-.8.4-.8.8V-1c0 .4.3.8.8.8s.8-.3.8-.8v-214.5c-.1-.5-.4-.8-.8-.8zM.9-216.3c-.4 0-.8.4-.8.8V-1c0 .4.3.8.8.8.4 0 .8-.4.8-.8v-214.5c0-.5-.3-.8-.8-.8zM172.4-216.2c-.4 0-.8.4-.8.8V-.8c0 .4.3.8.8.8s.8-.3.8-.8v-214.6c0-.4-.4-.8-.8-.8zM193.9-216.2c-.4 0-.8.4-.8.8V-1c0 .4.3.8.8.8s.8-.3.8-.8v-214.4c-.1-.4-.4-.8-.8-.8zM215.3-216.2c-.4 0-.8.4-.8.9V-1.1c-.1.4.2.8.6.9s.8-.2.9-.6v-214.5c.1-.5-.3-.9-.7-.9zM236.7-216.2c-.5.1-.8.5-.8 1V-1.1c-.1.4.2.8.6.9.4.1.8-.2.9-.6v-214.4c.1-.5-.2-.9-.7-1zM258.1-216.2c-.5.1-.8.5-.8 1V-1.1c-.1.4.2.8.6.9s.8-.2.9-.6v-214.4c.1-.5-.2-.9-.7-1zM279.6-216.2c-.5.1-.8.5-.8 1V-1.1c-.1.4.2.8.6.9s.8-.2.9-.6v-214.4c.1-.5-.3-.9-.7-1z"
      />
    </pattern>
    <title>{'Zas\xF3b 7'}</title>
    <g id="prefix__Warstwa_2_1_">
      <g id="prefix__Warstwa_1-28">
        <g className="prefix__st2-tag">
          <path
            className="prefix__st3-tag"
            d="M289.4 275.8h-73.1v-43.1h199.1v43.1h-73.1v185.3h-52.9V275.8z"
          />
        </g>
        <g className="prefix__st2-tag">
          <path
            className="prefix__st3-tag"
            d="M564.8 302.4c14.6 12.9 21.9 32.5 21.9 58.6v100.2H539v-21.9c-9.6 16.3-27.4 24.5-53.5 24.5-13.5 0-25.2-2.3-35.1-6.9-9.9-4.6-17.5-10.9-22.7-18.9-5.2-8-7.8-17.2-7.8-27.4 0-16.3 6.1-29.1 18.4-38.5 12.3-9.4 31.3-14 56.9-14h40.5c0-11.1-3.4-19.6-10.1-25.6-6.7-6-16.9-9-30.3-9-9.4 0-18.5 1.5-27.6 4.4-9 2.9-16.7 6.9-23 11.9l-18.3-35.6c9.6-6.7 21-12 34.4-15.7 13.4-3.7 27.1-5.5 41.3-5.5 27.2 0 48.1 6.5 62.7 19.4zm-43.4 121.1c6.7-4 11.5-10 14.4-17.8v-17.9h-34.9c-20.9 0-31.3 6.9-31.3 20.6 0 6.5 2.6 11.7 7.7 15.5 5.1 3.8 12.1 5.7 21 5.7 8.6-.1 16.4-2.1 23.1-6.1z"
          />
        </g>
        <g className="prefix__st2-tag">
          <path
            className="prefix__st3-tag"
            d="M815.7 285.6v146.2c0 32.2-8.4 56.1-25.1 71.8-16.8 15.7-41.2 23.5-73.4 23.5-17 0-33.1-2.1-48.3-6.2-15.2-4.1-27.8-10.1-37.9-17.9l20.2-36.5c7.4 6.1 16.7 10.9 28.1 14.5 11.3 3.6 22.6 5.4 33.9 5.4 17.6 0 30.6-4 39-11.9 8.4-7.9 12.6-20 12.6-36.1v-7.5c-13.3 14.6-31.8 21.9-55.5 21.9-16.1 0-30.8-3.5-44.2-10.6-13.4-7.1-24-17-31.8-29.9-7.8-12.8-11.7-27.6-11.7-44.4 0-16.8 3.9-31.5 11.7-44.4 7.8-12.8 18.4-22.8 31.8-29.9 13.4-7.1 28.1-10.6 44.2-10.6 25.5 0 44.8 8.4 58.1 25.1v-22.5h48.3zM752.6 399c8.6-7.9 12.9-18.3 12.9-31.2 0-12.8-4.3-23.2-12.9-31.2-8.6-7.9-19.6-11.9-33.1-11.9s-24.6 4-33.3 11.9c-8.7 7.9-13.1 18.3-13.1 31.2 0 12.8 4.3 23.2 13.1 31.2 8.7 7.9 19.8 11.9 33.3 11.9 13.5 0 24.5-3.9 33.1-11.9z"
          />
        </g>
        <g id="prefix__woman">
          <g id="prefix__tshirt7">
            <path
              d="M719.5 185.7c-.1-.1-.3-.2-.5-.2-5.6-.2-11.3-.4-17-.4-5.3 0-10.6.1-15.9.4-.3 0-.5.2-.6.5l-12.8 60.6c-4.7-2.8-9.7-5.7-14.9-8.6-6.5-3.7-13.3-7.4-20.1-11.1-4.4-2.4-9-4.8-13-6.9-11.9-6.2-19.6-9.8-26.4-11-15.5-2.8-39.5-.8-55.9 14-1.3 1.2-2.6 2.5-3.7 3.8-15.8 19.1-9.3 51.1-.9 74.8-2.5 29.5-5.4 65-6 74.6 0 .2.1.4.2.5.1.1.3.2.4.2h.1l133.2-13.3c.2 0 .4-.1.5-.3.1-.2.1-.4.1-.5-9-26.6-18.1-53.3-27.1-79.9l-2.1-6.1c2.2 1.2 3.9 2 4.1 2.2 7.2 3.6 31.7 11.8 48.5 2.1 11.1-6.4 14.8-21.1 21.5-47.9 3.9-15.4 6.7-31.1 8.4-46.9.1-.3 0-.4-.1-.6z"
              fill="#fff"
            />
            <path d="M652.6 236.4c-.1 0-.2 0-.3-.1-.3-.2-.4-.6-.2-.9l13.5-23.6c-6.1-1.8-11.8-3.5-17.5-5.2-5-1.5-10-3.1-15.1-4.8l-8 19c-.1.3-.5.5-.8.4-.3-.1-.5-.5-.4-.8v-.1l8.2-19.5c.1-.3.5-.5.8-.4 5.2 1.7 10.5 3.4 15.6 5 5.9 1.8 11.9 3.6 18.3 5.5.3.1.5.4.4.8 0 .1 0 .1-.1.2l-13.9 24.3c-.1.1-.3.2-.5.2z" />
            <path d="M538.5 302.1c-.3 0-.5-.2-.6-.4-8.3-23.6-15-55.8.9-74.9 1.1-1.4 2.4-2.7 3.7-3.8 16.4-14.7 40.4-16.8 55.9-14 6.8 1.2 14.5 4.8 26.4 11 4.1 2.1 8.7 4.6 13 6.9 6.9 3.7 13.6 7.4 20.1 11.1 5.2 2.9 10.2 5.8 14.9 8.6l12.9-60.6c.1-.3.3-.5.6-.5 5.3-.2 10.6-.4 15.9-.4 5.6 0 11.3.1 17 .4.2 0 .3.1.5.2.1.1.2.3.2.5-1.7 15.8-4.5 31.5-8.4 46.9-6.8 26.8-10.5 41.5-21.5 47.9-16.8 9.7-41.3 1.5-48.5-2.1-.2-.1-2.5-1.3-5.5-3-6.1-3.4-12-7.3-17.6-11.6-.2-.3-.2-.7 0-.9.2-.2.5-.2.8-.1 5.5 4.3 11.4 8.1 17.5 11.6 3 1.7 5.2 2.8 5.5 2.9 7.1 3.6 31 11.6 47.3 2.2 10.2-5.9 13.8-18.7 21-47.1 3.8-15.1 6.6-30.5 8.3-46-5.4-.2-10.8-.3-16.2-.3-5.1 0-10.3.1-15.3.4l-12.9 61c-.1.3-.4.6-.8.5-.1 0-.1 0-.2-.1-4.9-2.9-10.2-6-15.6-9.1-6.5-3.7-13.2-7.4-20.1-11.1-4.4-2.4-9-4.8-13-6.9-11.8-6.1-19.4-9.7-26-10.9-15.2-2.7-38.7-.8-54.8 13.7-1.3 1.1-2.5 2.4-3.6 3.7-8.5 10.3-15.6 31.4-.7 73.7.1.3-.1.7-.4.8-.6-.2-.7-.2-.7-.2z" />
            <path d="M532.5 376.8c-.2 0-.3-.1-.4-.2-.1-.1-.2-.3-.2-.5.6-9.6 3.4-45.3 6-74.8 1.5-16.9 2.6-29.3 3.3-35.9 0-.4.4-.6.7-.6.3 0 .6.4.6.7-.7 6.6-1.9 19.1-3.3 35.9-2.5 28.8-5.2 63.4-5.9 74l131.6-13.1c-9-26.4-17.9-52.8-26.9-79.1l-2.6-7.7c-.1-.3.1-.7.4-.8.3-.1.7.1.8.4l2.6 7.7c9 26.6 18.1 53.3 27.1 79.9.1.2 0 .4-.1.5-.1.2-.3.3-.5.3l-133.2 13.3z" />
          </g>
          <path
            id="prefix__hair4"
            d="M587.8 147.8c2.2 4.5-1.1 10-2.7 12.9-4.2 7.2-10.8 10.9-14.9 12.6 17.3 28.4 10.4 57.9-5 66.1-13.3 7.1-34.7-1.1-44.3-15.4-16.6-24.7 8.8-57.2 10.8-59.6 13.7-17 34.8-24.8 47.1-22.1 2.1.5 7.2 1.6 9 5.5z"
          />
          <g id="prefix__face7">
            <path d="M583.2 208.6c-.3 0-.6-.3-.6-.6l-.7-10.2c0-.4.2-.7.6-.7l.9-.1c2.8-.2 5.5-1.3 7.7-3.1 3.2-2.8 5-8 5-14.7-.1-4.2-1-8.3-2.6-12.2-2.3-6-5.7-11.5-10-16.2-.2-.2-.3-.6 0-.9.2-.2.6-.3.9 0 4.4 4.9 7.9 10.5 10.2 16.6 1.7 4 2.7 8.3 2.7 12.6 0 5-1 11.8-5.4 15.7-2.4 2-5.3 3.1-8.4 3.4h-.3l.7 9.5c-.1.4-.4.7-.7.9z" />
            <path d="M582.7 197.3c-.8.1-1.6.1-2.3 0-2.4-.1-4.7-.6-6.9-1.5l9.5 7.9-.3-6.4z" />
          </g>
          <g id="prefix__hand_r">
            <path d="M686.2 186.8c-.3 0-.5-.2-.6-.4-.8-2.1-1.5-4.2-2.2-6.3s-1.3-4-1.9-6.1c-.2-.6-1.6-5.8.3-7.9.7-.8 1.6-1.2 2.7-1.2 2.2.1 3.5 2.9 5.3 6.8v.1l.6 1.4c.3.7.6 1.3 1 2.3-.5-3.5-.9-7-1.3-10.5-1.4-12.2-1.7-17 1.1-18.5 1.1-.6 2.4-.6 3.5-.1 2.5 1.1 3 4.9 3.2 6.8l.5 3.9c.1.6.2 1.3.2 2v-.4c.2-6.7.4-13.6 3.8-14.8 1-.3 2.2-.2 3.1.4 2.4 1.5 2 5.6 1.7 9.4l-.1.7c-.2 1.9-.3 3.8-.4 5.8v-.2c.1-.8.2-1.4.4-2.1 1.1-5.4 1.7-8.7 4.2-9.6 1.1-.4 2.4-.3 3.4.3 2.1 1.5 1.6 5.1 1 9.2v.3c-.1.7-.2 1.2-.2 1.7s-.2 1.3-.3 2.2l-.3 2 .6-2c1.4-4.6 2-6.8 4-7.2.9-.2 1.9 0 2.7.6 1.4 1.2 1.2 3.3-.1 9.5-1.1 5-1.4 6.2-1.4 6.3-.9 3.1-1.9 6.3-2.9 9.7l-1.6 5.4c-.1.3-.4.6-.8.5s-.6-.4-.5-.8v-.1l1.6-5.4 2.9-9.7s.4-1.3 1.4-6.2c1.2-5.8 1.3-7.6.6-8.3-.4-.3-1-.4-1.5-.3-1.2.3-1.8 2.3-3 6.4-.2.6-.4 1.3-.6 2.1l-2.1 6.6c-.1.3-.4.5-.8.5-.3-.1-.5-.4-.5-.7.3-3 .7-6 1.2-8.9.1-.9.2-1.5.3-2.2s.1-1.1.2-1.7v-.3c.5-3.6 1-6.9-.4-8-.7-.4-1.5-.5-2.2-.2-1.8.7-2.4 3.9-3.4 8.7-.2.7-.2 1.3-.4 2l-.1.4c-.5 3-1 6.2-1.4 9.4 0 .1-.1.3-.2.4-.2.2-.5.2-.7.1-.2-.1-.4-.3-.4-.6-.1-5.5.1-10.7.5-15.6l.1-.7c.3-3.5.6-7.2-1.1-8.3-.6-.4-1.3-.5-2-.3-2.6.9-2.8 8-3 13.6v5.1c0 .6 0 1.1.1 1.7.1 1.1.1 2.5.1 4.2v1.1c0 .3-.3.6-.6.6s-.6-.2-.7-.6c-.6-5-1.1-9.6-1.6-13.6l-.5-3.9c-.2-1.6-.6-4.9-2.5-5.7-.8-.3-1.6-.3-2.3.1-2 1.1-1.7 6.3-.4 17.2.5 4.6 1.1 9.4 1.8 14.1 0 .4-.2.7-.6.7-.3 0-.5-.1-.6-.3-.5-.9-1-1.8-1.4-2.7-.7-1.3-1-2.2-1.4-3-.2-.4-.4-.9-.6-1.3v-.1c-1.5-3.2-2.8-6-4.2-6-.6 0-1.2.3-1.7.8-1.1 1.2-.6 4.7 0 6.7l1.8 6c.6 2 1.4 4.2 2.2 6.2.1.3 0 .7-.4.8h-.2z" />
            <path d="M686 185.7l30.3-2.2-.7 2.3-29.4.4-.2-.5z" />
          </g>
          <g id="prefix__hand_l4">
            <path d="M646.7 197.4h-.2c-.3-.1-.5-.4-.5-.8 3.3-14.1 5.7-23.6 6.6-26.4.2-.8.9-2.8 2.6-3.5 1-.4 2.1-.3 3 .3 1.5 1.1 1.3 3.7 1.2 4.8-.3 3.1-1 7.3-1.7 10.8.7-1.6 1.5-3.2 2.2-4.8 1.2-2.5 2.5-5 4-7.3.2-.3.6-.4.9-.2.3.2.4.6.2.9-1.4 2.3-2.8 4.7-3.9 7.2-1.7 3.6-3.3 7.3-4.7 11-.1.3-.5.5-.8.4-.3-.1-.5-.4-.4-.8.4-1.9 2.4-11.5 3-17.3.1-.9.3-2.9-.6-3.6-.5-.3-1.2-.4-1.8-.1-1.2.4-1.6 2-1.8 2.7-.9 2.8-3.3 12.3-6.6 26.3-.1.3-.4.5-.7.4zM661.8 191.8h-.2c-.3-.1-.5-.5-.4-.8 1.6-4.9 3.6-9.6 6-14.1.2-.4.5-.8.7-1.2s.6-1.1.9-1.6c.2-.3.5-.4.9-.3.3.2.4.5.3.9l-.9 1.7-.7 1.2c-2.4 4.4-4.4 9.1-5.9 13.9-.2.1-.4.2-.7.3zM662.7 210.9c-.1 0-.3 0-.4-.1-.3-.2-.4-.6-.1-.9 5.1-6.8 9.9-13.3 13.1-17.6l.1-.1c1.2-1.6 4.5-5.8 3.3-7.7-.2-.3-.4-.5-.7-.6-.9-.2-2.3 1.6-4 3.7-.4.6-.9 1.1-1.4 1.8-1.8 2.2-3.7 4.4-5.8 6.4-.2.3-.6.3-.9.1s-.3-.6-.1-.9l.1-.1c2-2 3.9-4.1 5.7-6.2.5-.6 1-1.2 1.4-1.8 2.1-2.6 3.6-4.6 5.3-4.1.6.2 1.2.6 1.5 1.2 1.5 2.6-1.8 7-3.4 9.1l-.1.1c-3.3 4.3-8.1 10.7-13.1 17.6-.1 0-.3.1-.5.1z" />
            <path d="M662 191.3c-.1 0-.2 0-.3-.1-.3-.2-.4-.6-.2-.9 2-3.6 4-7.1 5.7-10.4l.2-.3c.8-1.8 1.9-3.4 3.2-4.9.2-.3.6-.3.9-.1.3.2.3.6.1.9l-.1.1c-1.2 1.4-2.2 3-2.9 4.6l-.2.3c-1.8 3.4-3.7 6.9-5.8 10.4-.2.3-.4.4-.6.4zM666.4 196c-.4 0-.6-.3-.7-.6 0-.1 0-.3.1-.4 2-4.3 4.2-8.4 6.5-12.4l.4-.7c1-1.5 1.8-3.1 2.4-4.8.1-.3.4-.6.8-.5s.6.4.5.8v.1c-.7 1.8-1.5 3.5-2.5 5.1l-.4.7c-2.2 3.9-4.4 8.1-6.4 12.3 0 .1-.1.1-.1.2-.3.1-.4.2-.6.2zM652.2 204.4c-.3 0-.5-.2-.6-.4-.3-1-.7-2-1.3-2.9-1-1.5-2.3-2.8-3.9-3.7-.2-.1-.3-.3-.3-.6.2-3.6.2-7.2.1-10.6-.1-2.9-.2-5.1-1.4-5.7-.6-.3-1.3-.3-1.9 0-1.3.6-1.6 2.5-2.1 5.2l-.1.5-3.5 16.4c-.1.3-.4.6-.7.5s-.6-.4-.5-.7v-.1l3.6-16.8c.5-2.9.9-5.3 2.8-6.1 1-.4 2.1-.4 3 .1 1.8 1 2 3.3 2 6.8s0 6.9-.1 10.4c1.6 1 3 2.3 4 3.9.6 1 1.1 2.1 1.5 3.2.1.3-.1.7-.4.8-.1-.2-.1-.2-.2-.2z" />
            <path d="M667.1 197.1c-.2 0-.4-.1-.6-.3-1.5-2.6-3.8-4.6-6.5-5.8-2.1-.9-4.3-1.1-6.5-.8-.3.1-.7-.2-.7-.5-.1-.3.2-.7.5-.7 2.4-.3 4.9 0 7.2.9 3 1.3 5.5 3.6 7.1 6.4.2.3.1.7-.2.9-.1-.1-.2-.1-.3-.1zM636.9 201.5l27.1 7-1.1 1.6-26.1-8.1.1-.5z" />
          </g>
          <g id="prefix__legs5">
            <path
              className="prefix__st5-tag"
              d="M595.5 424.9c-6.8 57-13.6 114-20.3 171L501.9 578l49.2-203.7 95.1-9.1c16.9 68.9 33.8 137.9 50.7 206.8-14.1 4.7-28.7 9.2-43.9 13.5-9 2.5-17.8 4.9-26.5 7.2-11.4-61.8-22.8-123.5-34.2-185.3"
            />
            <pattern
              id="prefix__SVGID_1_"
              xlinkHref="#prefix__Nowy_wzorek_2"
              patternTransform="matrix(1 0 0 -1 6.37 -16324.38)"
            />
            <path
              d="M595.5 424.9c-6.8 57-13.6 114-20.3 171L501.9 578l49.2-203.7 95.1-9.1c16.9 68.9 33.8 137.9 50.7 206.8-14.1 4.7-28.7 9.2-43.9 13.5-9 2.5-17.8 4.9-26.5 7.2-11.4-61.8-22.8-123.5-34.2-185.3"
              fill="url(#prefix__SVGID_1_)"
            />
            <path d="M672.9 603.3c-.3 0-.6-.2-.6-.6-.1-1.5-.2-3-.2-4.5l-1-18.2c0-.3.3-.6.6-.7.3 0 .7.2.7.6l1 18.2c.1 1.5.2 3 .2 4.4-.1.4-.3.7-.7.8zM644.7 612.7c-.3 0-.6-.2-.6-.5l-3.8-23.2c-.1-.3.2-.7.5-.7.3-.1.7.2.7.5l3.7 23.2c.1.3-.2.7-.5.7zM523.1 603.6c-.4 0-.7-.4-.6-.7l2.6-19.2c0-.3.4-.6.7-.5.4 0 .6.4.6.7l-2.6 19.2c-.1.3-.4.5-.7.5zM552.6 609.3h-.1c-.3-.1-.6-.4-.5-.7l.2-1.2 2.8-14.9.2-1.3c.1-.3.5-.5.8-.4.3.1.5.4.4.7l-.2 1.3c-.9 5-1.8 10-2.8 14.9l-.2 1.2c0 .2-.3.4-.6.4zM658.9 584.5c-.3 0-.6-.2-.6-.6l-23.1-217.6c0-.4.3-.6.6-.7.3 0 .6.2.6.5l23.1 217.6c.1.4-.2.7-.6.8zM541.4 588.3h-.2c-.3-.1-.5-.5-.4-.8l52.1-173.6c.1-.3.5-.5.8-.4.3.1.5.5.4.8L542 587.8c-.1.3-.3.5-.6.5z" />
            <path d="M525.5 584.6L540 590l15.2 5.7.8-4.5-30.2-7.5-.3.9zM671.7 580.8l-30 13c-.2-1.5-.5-3.1-.7-4.6l30.7-9.2v.8zM646.3 365.7l-96.5 14 1.3-5.4 95.1-9.5.1.9z" />
          </g>
          <g id="prefix__shoes3">
            <path d="M521.7 601.5l32.6 6.2c2.1 4.3 5 8.1 8.4 11.5.8.7 2.7 2.3 8 5.2 7.3 4.1 11.2 5.3 14.1 6.8 4.6 2.4 10.3 7.4 14.7 19.4l-46.2-8.4-12.6-2.3c-3.3-3.5-6.7-7-10-10.5l-6.3 7.8c-5.2-1-9.5-1.8-12.6-2.5 3.2-11.1 6.6-22.1 9.9-33.2zM642.4 611.6l30.3-13.5c4.2 2.3 8.7 3.9 13.4 4.7 1 .2 3.5.3 9.5-.2 8.3-.8 12.3-2 15.5-2.4 5.1-.7 12.7.2 23.1 7.6-14.2 6.5-28.5 12.9-42.8 19.3l-11.7 5.2-14.2-3c-.2 3.3-.5 6.7-.7 10-4.8 2.1-8.8 3.9-11.8 5.2-3.5-10.9-7.1-21.9-10.6-32.9z" />
          </g>
          <path d="M664.7 172c.9-1.1 2.2-3 3.9-2.2s.6 2.9.1 4.1c-.3.8 1 1.1 1.2.3.7-1.9 1.6-4.3-.7-5.6s-4.2.8-5.5 2.4c-.5.7.4 1.6 1 1zM670.6 176.3c.9-1.1 2.2-3 3.9-2.2s.6 2.9.1 4.1c-.3.8 1 1.1 1.2.4.7-1.9 1.6-4.3-.7-5.6s-4.2.8-5.5 2.4c-.5.6.5 1.5 1 .9z" />
        </g>
        <g id="prefix__background2">
          <path
            className="prefix__st3-tag"
            d="M1082.6 255.9c-9.4 0-19.3-6.9-29.4-20.4-.3-.4-.2-1 .2-1.3.4-.3 1-.2 1.3.2 10.1 13.5 19.8 20.1 28.8 19.7 7.4-.4 12.4-5.2 17.8-10.2s11.1-10.5 19.4-10.9c9.2-.4 18.7 5.4 28.2 17.4.3.4.2 1-.1 1.3-.4.3-1 .2-1.3-.1-9.2-11.5-18.2-17.1-26.7-16.7-7.6.4-12.8 5.2-18.2 10.4s-11 10.3-19 10.7c-.3-.2-.6-.1-1-.1zM33.7 437.9c-10.7 0-22-7.8-33.5-23.3-.3-.4-.2-1 .2-1.3.4-.3 1-.2 1.3.2C13.3 429 24.4 436.6 34.8 436c8.5-.4 14.3-5.9 20.5-11.8s12.7-12 22.1-12.4c10.4-.5 21.3 6.2 32.2 19.8.3.4.2 1-.1 1.3s-1 .2-1.3-.1c-10.5-13.1-20.8-19.6-30.7-19.1-8.7.4-14.7 6-20.9 11.9S44 437.4 34.8 437.9h-1.1zM313.6 29.1c-10 0-20.6-7.3-31.4-21.8-.3-.4-.2-1 .2-1.3.4-.3 1-.2 1.3.2 10.8 14.5 21.2 21.5 30.9 21 7.9-.4 13.3-5.5 19.1-11s11.9-11.1 20.7-11.5c9.8-.5 19.9 5.8 30.1 18.5.3.4.2 1-.2 1.3-.4.3-.9.2-1.2-.1-9.8-12.3-19.5-18.3-28.6-17.9-8.1.4-13.7 5.6-19.5 11.1s-11.7 11-20.3 11.4c-.4 0-.7.1-1.1.1z"
          />
          <path
            className="prefix__st1-tag"
            d="M20 71.2L.4 58.2l13.7-18.8 19.6 13.1z"
          />
          <path
            className="prefix__st3-tag"
            d="M915 542.8h-.2l-11.9-2.1c-.2 0-.5-.2-.6-.4-.1-.2-.2-.4-.1-.7l2.2-11.4c.1-.5.5-.8 1-.7l11.9 2.1c.2 0 .5.2.6.4.1.2.2.4.2.7l-2.2 11.4c-.1.4-.5.7-.9.7zm-10.9-3.7l10.2 1.8 1.8-9.6-10.1-1.8-1.9 9.6zM410.2 552.4c-.3 0-.6-.2-.8-.4-.3-.4-.2-1 .3-1.2l19.4-12.8c.4-.3 1-.3 1.3.1.3.4.3 1-.1 1.3l-.1.1-19.4 12.7c-.3.1-.5.2-.6.2zM384.2 122.6c-.3 0-.6-.2-.8-.4L371.1 103c-.3-.4-.2-1 .3-1.3.4-.3 1-.2 1.3.3l12.3 19.2c.3.4.2 1-.3 1.2-.2.1-.4.2-.5.2zM1108.6 539.8c-.3 0-.5-.1-.7-.3l-14.5-17.8c-.3-.4-.3-1 .1-1.3s1-.3 1.3.1l14.5 17.8c.3.4.3 1-.1 1.3-.2.1-.4.2-.6.2zM777.7 168.5c-.3 0-.6-.1-.7-.4-.3-.4-.2-1 .2-1.3l18.8-13.5c.4-.3 1-.2 1.3.2.3.4.2 1-.2 1.3l-18.8 13.5c-.2.1-.4.2-.6.2zM997.7 388.3c-.2 0-.5-.1-.7-.3l-16.9-15.7c-.4-.4-.3-.9 0-1.3.3-.3.9-.4 1.2 0l16.9 15.7c.4.3.4.9 0 1.3-.1.1-.3.2-.5.3zM756.7 16.3c-.2 0-.4-.1-.6-.2l-18-14.5c-.4-.3-.5-.9-.1-1.3.3-.4.9-.5 1.3-.1l18 14.5c.4.3.5.9.2 1.3-.3.2-.5.3-.8.3z"
          />
          <ellipse
            className="prefix__st1-tag"
            cx={1131.5}
            cy={101.2}
            rx={11.6}
            ry={11.1}
          />
          <path
            className="prefix__st3-tag"
            d="M62.6 240.2c-3.9.1-7.2-2.8-7.7-6.7-.2-1.9.4-3.8 1.6-5.3 2.8-3.3 7.7-3.8 11.1-1 1.5 1.2 2.5 3 2.7 4.9.2 1.9-.4 3.8-1.6 5.3-1.3 1.6-3.3 2.6-5.3 2.8h-.8zm0-13.1H62c-1.6.2-3 .9-4 2.2-.9 1.1-1.4 2.6-1.2 4 .4 3.2 3.3 5.4 6.5 5.1 1.6-.1 3.1-.9 4.1-2.1.9-1.1 1.4-2.6 1.2-4-.4-3-3-5.2-6-5.2zM926.2 196.6c-2 0-4-.8-5.5-2.2-2.9-2.7-3.1-7.3-.3-10.2l.3-.3c3.1-2.9 7.9-2.9 10.9 0 2.9 2.7 3.1 7.3.3 10.2l-.3.3c-1.4 1.4-3.4 2.2-5.4 2.2zm-4.2-11.4c-2.2 2-2.3 5.5-.3 7.6l.3.3c2.4 2.2 6.1 2.2 8.4 0 2.2-2 2.3-5.5.3-7.7l-.3-.3c-2.3-2.1-6-2.1-8.4.1zM244.4 568.6c-.2 0-.4-.1-.6-.2-.4-.3-.4-.9-.1-1.3l15.6-16.9c.3-.4.9-.4 1.3 0 .4.3.4.9.1 1.3L245 568.3c-.2.2-.4.3-.6.3z"
          />
        </g>
        <path className="prefix__st3-tag" d="M63 664.6h689v1.5H63z" />
        <path
          className="prefix__st5-tag"
          d="M701.7 123.3l-20.4 4.7-4-17.7c-1-4.6-8.1-3-7.1 1.6l4 17.7-9.4 2.1c-1.4-6.3-2.9-12.6-4.3-18.9-1-4.6-8.1-3-7.1 1.6l4.3 18.9-18.9 4.3c-4.5 1-3 8.1 1.6 7.1l18.9-4.3 3.1 13.7-18.9 4.3c-4.5 1-3 8.1 1.6 7.1l18.9-4.3c1.2 5.3 2.4 10.7 3.7 16.1 1 4.6 8.1 2.9 7.1-1.6-1.2-5.4-2.4-10.7-3.7-16.1l9.4-2.1c.7 2.9 1.3 5.9 2 8.8 1.3-1.3 3.5-1.6 4.6.5 1.4 2.9 3 5.6 4.3 8.5.2-.6.3-1.3.1-2-1.3-5.8-2.7-11.6-4-17.4l1.8-.4c-.3-1.8-.3-3.6-.2-5.4.1-.8.4-1.6 1-2.2l-4.3 1-3.1-13.8 20.4-4.6c4.7-1.2 3.1-8.3-1.4-7.2zm-32.2 29.2l-3.1-13.7 9.4-2.1 3.1 13.7-9.4 2.1z"
        />
        <path
          className="prefix__st5-tag"
          d="M698.1 153.4l1.2-.3c.2-1.9.7-3.7.8-5.6.2-.8.5-1.5.9-2.3l-5.5 1.2c2.2 1.2 2.7 4.4 2.6 7zM706.4 144.1l-.8.2c.5.2.8.6 1.1 1 .5.5.7 1.2.7 1.9.2 1.3.2 2.6.1 3.9l-.1.2.5-.1c4.6-1.1 3.1-8.2-1.5-7.1z"
        />
        <path
          className="prefix__st1-tag"
          d="M655.4 114l14.3 62.9c.2 1 1.7.6 1.5-.4l-14.3-62.9c-.2-1.1-1.7-.6-1.5.4zM686.1 165.5c-4.1-18.2-8.3-36.3-12.4-54.5-.2-1-1.7-.6-1.5.4 4.1 17.9 8.2 35.8 12.2 53.7.6 0 1.2.1 1.7.4z"
        />
        <path
          className="prefix__st1-tag"
          d="M701.5 126.2l-62.9 14.3c-1 .2-.6 1.7.4 1.5l62.9-14.3c1-.2.6-1.7-.4-1.5zM689.2 150.9l-45.9 10.4c-1 .2-.6 1.7.4 1.5l45.4-10.3c.1-.5.1-1.1.1-1.6z"
        />
        <path className="prefix__st3-tag" d="M345 649.6h196v1.5H345z" />
      </g>
    </g>
  </svg>
);
